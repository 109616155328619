export const meta: BlockMeta = {
  editMode: "both",
  title: "Vertical space",
  description: "",
  keywords: ["vertical", "space"],
  // @ts-ignore
  icon: "image-flip-vertical",
  cache: false,
  tags: ["root"],
  conditions: {
    multiple: true,
  },
}

import { defineBlock } from "eddev/blocks"
import { GridLines } from "@components/atoms/GridLines"

export default defineBlock("content/vertical-space", (props) => {
  return (
    <div className={`subtheme-${props.scheme} relative`}>
      {props.gridlines && <GridLines />}
      <div className="w-full h-full flex flex-col bg-bg overflow-hidden">
        {[...Array(props.rows)].map((_, i) => (
          <div className="h-[56px] md:h-[80px] w-full" key={i} />
        ))}
      </div>
    </div>
  )
})
